import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import AppleIcon from '@mui/icons-material/Apple'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Logo, Link } from '@components'
import { paths } from 'routes'
import { withTranslation, useTranslation } from 'react-i18next'
import GoogleLogo from 'svgs/icons/google_logo.svg'

import { snackActions } from 'utils/SnackbarUtils'
const PREFIX = 'adminLogin'

const classes = {
	root: `${PREFIX}-root`,
	header: `${PREFIX}-header`,
	image: `${PREFIX}-image`,
	paper: `${PREFIX}-paper`,
	iconWrapper: `${PREFIX}-iconWrapper`,
	avatar: `${PREFIX}-avatar`,
	form: `${PREFIX}-form`,
	boldLink: `${PREFIX}-boldLink`,
	actionsContainer: `${PREFIX}-actionsContainer`,
	submit: `${PREFIX}-submit`,
	googleButton: `${PREFIX}-googleButton`,
	appleButton: `${PREFIX}-appleButton`,
	iconSpacing: `${PREFIX}-iconSpacing`,
	buttonText: `${PREFIX}-buttonText`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
	[`&.${classes.root}`]: {
		height: '100vh',
	},

	[`& .${classes.header}`]: {
		padding: 20,
	},

	[`& .${classes.image}`]: {
		backgroundImage: 'url(/images/DigitalHealth.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},

	[`& .${classes.paper}`]: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	[`& .${classes.iconWrapper}`]: {
		margin: 'auto',
		lineHeight: 1,
	},

	[`& .${classes.avatar}`]: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},

	[`& .${classes.form}`]: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},

	[`& .${classes.boldLink}`]: {
		fontWeight: 'bold',
	},

	[`& .${classes.actionsContainer}`]: {
		marginBottom: theme.spacing(1),
	},

	[`& .${classes.submit}`]: {
		marginTop: theme.spacing(1),
	},

	[`& .${classes.googleButton}`]: {
		color: theme.palette.common.black,
		backgroundColor: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.common.white,
			opacity: `.8`,
		},
	},

	[`& .${classes.appleButton}`]: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.common.black,
		'&:hover': {
			backgroundColor: theme.palette.common.black,
			opacity: `.8`,
		},
	},

	[`& .${classes.iconSpacing}`]: {
		marginLeft: theme.spacing(2),
	},

	[`& .${classes.buttonText}`]: {
		paddingRight: theme.spacing(2),
	},
}))

const displaySnackbarError = (message) => {
	snackActions.error(message)
}

function Copyright() {
	const { t } = useTranslation()
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{t('signup.copyright')}
			<Link variant="body2" href={'https://www.dietahealth.com'}>
				{t('signup.dieta_health')}
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}

const auth0ErrorMapper = (errorPayload) => {
	const error = errorPayload && errorPayload.error
	let errorMessage = 'Authentication Failed!'
	if (error) {
		errorMessage = error.description
	}
	if (errorMessage === 'Global limit has been reached') {
		window.alert(
			'User Registration temporarily disabled',
			"We're experiencing a large amount of traffic and have put access enforcements in place at this time.  Sorry about that. Try again later"
		)
	}
	if (error && error.name) {
		if (error.code === 'Missing required parameters') {
			errorMessage = 'Invalid credentials'
		}
		if (error.code === 'a0.response.invalid') {
			errorMessage = 'Invalid credentials'
		}
		if (error.code === 'too_many_attempts') {
			errorMessage = error.description
		}
	}
	return errorMessage
}

class AdminLogin extends Component {
	constructor(props) {
		super(props)
		this.emailRef = React.createRef()
		this.passwordRef = React.createRef()
		this.userToken = React.createRef()
	}

	state = {
		authError: null,
		loading: false,
	}

	static propTypes = {
		auth: PropTypes.shape({
			accessToken: PropTypes.string,
		}).isRequired,
		loginAdminUser: PropTypes.func.isRequired,
	}

	static defaultProps = {
		auth: {
			accessToken: null,
		},
	}

	onPressSocialGoogle = async () => {
		this.setState({
			loading: true,
		})
		const res = await this.props.loginUser(true, {
			identifier: 'google-oauth2',
		})
		if (res.error) {
			const error = auth0ErrorMapper(res)
			this.setState({
				authError: error,
			})
			displaySnackbarError(error)
		}
		this.setState({
			loading: false,
		})
	}

	onPressSocialApple = async () => {
		const target = this.userToken.value
		this.setState({
			loading: true,
		})

		const res = await this.props.loginAdminUser(true, {
			identifier: 'apple',
			target,
		})
		if (res.error) {
			const error = auth0ErrorMapper(res)
			this.setState({
				authError: error,
			})
			displaySnackbarError(error)
		}
		this.setState({
			loading: false,
		})
	}

	onPressLogin = async (e) => {
		e.preventDefault()
		const email = this.emailRef.value
		const password = this.passwordRef.value
		const target = this.userToken.value

		this.setState({
			loading: true,
		})
		const res = await this.props.loginAdminUser(false, {
			email,
			password,
			target,
		})
		if (res.error) {
			const error = auth0ErrorMapper(res)
			this.setState({
				authError: error,
			})
			displaySnackbarError(error)
		}
		this.setState({
			loading: false,
		})
	}

	render() {
		const { t } = this.props
		return (
			<StyledGrid justifyContent="center" container component="main" className={classes.root}>
				<CssBaseline />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<div className={classes.paper}>
						<Grid container alignItems="center" spacing={2} direction="row">
							<Grid item className={classes.iconWrapper}>
								<Logo scale={1} />
							</Grid>
						</Grid>
						<Typography component="h1" variant="h5" className={classes.header}>
							{t('adminLogin.signin')}
						</Typography>
						<form
							className={classes.form}
							noValidate
							onSubmit={(e) => {
								this.onPressLogin(e)
							}}
						>
							<TextField
								inputRef={(ref) => (this.emailRef = ref)}
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label={t('adminLogin.email')}
								name="email"
								autoComplete="email"
								autoFocus
							/>
							<TextField
								inputRef={(ref) => (this.passwordRef = ref)}
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label={t('adminLogin.password')}
								type="password"
								id="password"
								autoComplete="current-password"
							/>

							<TextField
								inputRef={(ref) => (this.userToken = ref)}
								variant="outlined"
								margin="normal"
								required
								fullWidth
								label={t('adminLogin.target_user')}
								autoComplete="off"
							/>

							<Grid container spacing={3} className={classes.actionsContainer}>
								<Grid item xs={12}>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
									>
										{t('adminLogin.signin')}
									</Button>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Button
										onClick={this.onPressSocialGoogle}
										fullWidth
										variant="contained"
										color="primary"
										className={classNames(classes.googleButton)}
										startIcon={<GoogleLogo className={classes.iconSpacing} />}
									>
										<ListItemText
											className={classes.buttonText}
											classes={{
												primary: classes.itemPrimary,
												textDense: classes.textDense,
											}}
										>
											{t('adminLogin.signin_google')}
										</ListItemText>
									</Button>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Button
										onClick={this.onPressSocialApple}
										fullWidth
										variant="contained"
										color="primary"
										className={classNames(classes.appleButton)}
										startIcon={<AppleIcon className={classes.iconSpacing} />}
									>
										<ListItemText
											className={classes.buttonText}
											classes={{
												primary: classes.itemPrimary,
												textDense: classes.textDense,
											}}
										>
											{t('adminLogin.signin_apple')}
										</ListItemText>
									</Button>
								</Grid>
							</Grid>
						</form>
					</div>
				</Grid>
			</StyledGrid>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(AdminLogin)
